import React, { useEffect, useState } from 'react';
import { adminApproveKyc, adminPendingKyc, adminRejectedKyc } from '../../store/reducer/adminReducer';
import { useDispatch, useSelector } from 'react-redux';

const PendingKyc = () => {
  const dispatch = useDispatch();
  const {userKycPending} = useSelector((state)=>state.admin);
      // Sample data array
  const data = [
    { id: 1, userId: 'I8EFD884', documentName: 'Voter ID Card', documentNumber: 'demo_document_number' },
    { id: 2, userId: 'Akauak', documentName: 'Voter ID Card', documentNumber: 'updated_document_number' },
    { id: 3, userId: 'IZZU KHAN', documentName: 'Voter ID Card', documentNumber: 'updated_document_number' },
    { id: 4, userId: '9LJW3839', documentName: 'Voter ID Card', documentNumber: 'updated_document_number' },
  ];

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = userKycPending?.filter(entry => 
    entry?.phone?.toString().includes(searchTerm)
  );
        const handleApprove = (id)=>{
          dispatch(adminApproveKyc(id)).then(()=>{
            dispatch(adminPendingKyc())
          })
        }
        const handleReject = (id)=>{
          dispatch(adminRejectedKyc(id)).then(()=>{
            dispatch(adminPendingKyc())
          })
        }

  useEffect(()=>{
    dispatch(adminPendingKyc())
  },[])

  return (
    <div className='w-[100%]'>
       <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">KYC</h1>
      <h2 className="text-xl font-semibold mb-2">Pending KYC</h2>
      <div className="flex justify-between mb-4">
        <div>
          <input 
            type="text" 
            placeholder="Search" 
            className="border border-zinc-300 rounded p-1" 
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <div className="overflow-x-auto sm:w-[310px] md:w-[100%]">
        <table className="min-w-full border border-zinc-300">
          <thead className="bg-zinc-100">
            <tr>
              <th className="border border-zinc-300 p-2">#</th>
              <th className="border border-zinc-300 p-2">User ID</th>
              <th className="border border-zinc-300 p-2">Phone</th>
              <th className="border border-zinc-300 p-2">Document Name</th>
              <th className="border border-zinc-300 p-2">Document Number</th>
              <th className="border border-zinc-300 p-2">Action</th>
            </tr>
          </thead>
          <tbody className='bg-white'>
            {Array.isArray(userKycPending) && filteredData.map((entry, index) => (
              <tr key={entry.id}>
                <td className="border border-zinc-300 p-2">{index + 1}</td>
                <td className="border border-zinc-300 p-2">{entry.orderid}</td>
                <td className="border border-zinc-300 p-2">{entry.phone}</td>
                <td className="border border-zinc-300 p-2">
                  <img src={entry.image1} alt="" className='w-[200px] h-[100px]' />
                </td>
                <td className="border border-zinc-300 p-2">
                  <img src={entry.image2} alt="" className='w-[200px] h-[100px]'/>
                </td>
                <td className="border border-zinc-300 p-2">
                  <button className="bg-green-500 text-white hover:bg-green-600 rounded p-1 mr-2" onClick={()=>handleApprove(entry._id)}>Approve</button>
                  <button className="bg-red-500 text-white hover:bg-red-600 rounded p-1" onClick={()=>handleReject(entry._id)}>Reject</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  )
}

export default PendingKyc
